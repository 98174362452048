import LogInFormProvider from '@jetshop/ui/Auth/LogInFormProvider';
import t from 'format-message';
import React from 'react';
import { styled } from 'linaria/react';
import { css, cx } from 'linaria';
import { Link } from 'react-router-dom';
import { GlobalError } from '../Forms/GlobalError';
import Input from '../Forms/Input';
import MaxWidth from '../Layout/MaxWidth';
import TrendButton from '../ui/Button';
import { smallCaps } from '../ui/Headings';
import { activeSegment, smallSection } from './UI/Form';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import { Intl } from '@jetshop/intl';
import Head from '@jetshop/core/components/Head';
import { Helmet } from 'react-helmet-async';

import { theme } from '../Theme';
import useChannelCheck from '../../hooks/useChannelCheck';

const StyledTrendButton = styled(TrendButton)`
  align-self: center;
  width: 100%;
  padding: 13px 30px 10px 30px;
  border-radius: ${theme.button.desktop.borderRadius};
  background: ${theme.colors.accent};
  color: ${theme.colors.accentTextColor};
`;

const actionWrapper = css`
  border: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 15rem;
  margin-bottom: 1rem;
`;

const TextLink = styled(Link)`
  text-decoration: none;
  color: ${theme.colors.black};
  text-align: right;
  font-size: 0.875em;
  :hover {
    opacity: 0.8;
  }
  &.signup-link {
    margin-left: auto;
  }
`;

export const LogInPageMaxWidth = styled(MaxWidth)`
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  width: 100%;
  h1 {
    font-size: 43px;
    font-family: Averta Std;
    font-weight: 700;
  }
`;

const LoginMessageWrapper = styled.div`
  margin: 1rem 0;
  text-align: center;
  p {
    margin-bottom: 1rem;
  }
  a {
    color: ${theme.colors.black};
    text-decoration: none;
    font-weight: 700;
    :hover {
      opacity: 0.8;
    }
  }
`;

// const LoginMessage = () => {
//   const { isEU, isSE } = useChannelCheck();
//   if (isEU) {
//     return (
//       <LoginMessageWrapper>
//         <p>
//           Handlar du i Svenska kronor, logga in på{' '}
//           <a href="https://www.willab.se/login">www.willab.se</a>
//         </p>
//         <p>
//           If you are shopping in SEK, please log in at{' '}
//           <a href="https://www.willab.se/login">www.willab.se</a>
//         </p>
//       </LoginMessageWrapper>
//     );
//   }
//   if (isSE) {
//     return (
//       <LoginMessageWrapper>
//         <p>
//           Handlar du i Euro, logga in på{' '}
//           <a href="https://www.willab.eu/login">www.willab.eu</a>
//         </p>
//         <p>
//           If you are shopping in Euro, please log in at{' '}
//           <a href="https://www.willab.eu/login">www.willab.eu</a>
//         </p>
//       </LoginMessageWrapper>
//     );
//   }
// };

function LogInPage() {
  const { routes } = useShopConfig();

  return (
    <LogInPageMaxWidth className="grayBg">
      <Intl>{t => <Head data={{ title: t('Login') }} />}</Intl>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <h1 style={{ marginBottom: 0 }}>{t('Login')}</h1>
      <LogInFormProvider redirect="my-pages">
        {({ globalError, isSubmitting, isValid }) => (
          <section className={cx(smallSection, activeSegment)}>
            {/* <LoginMessage /> */}
            <Input type="email" name="email" label={t('E-mail address')} />
            <Input type="password" name="password" label={t('Password')} />

            {globalError && (
              <GlobalError style={{ marginBottom: '2em' }}>
                {globalError}
              </GlobalError>
            )}

            <section className={actionWrapper}>
              <StyledTrendButton
                type="submit"
                disabled={!isValid}
                loading={isSubmitting}
                loadingText={t('Hold on a moment...')}
              >
                {t('Log in')}
              </StyledTrendButton>
            </section>
            <TextLink to={routes.forgotPassword.path}>
              {t('Forgot password?')}
            </TextLink>
          </section>
        )}
      </LogInFormProvider>
    </LogInPageMaxWidth>
  );
}

export default LogInPage;

import { css } from 'linaria';
import { theme } from '../../Theme';

export const smallSection = css`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 30rem;
  padding: 2rem 4rem;
  background: #fff;
  max-width: 100%;
  align-items: center;

  label {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  ${theme.below.sm} {
    width: 100%;
  }
`;

export const widerSection = css`
  max-width: 40rem;
  width: 100%;
`;

export const activeSegment = css``;
